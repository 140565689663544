(function () {
    const transcludeNames = {
        anchor: 'anchor',
        body: 'body',
        header: 'header',
        controls: 'controls'
    };

    angular.module('informaApp')
        .component('modalWindow', {
            templateUrl: 'components/modal-window/template.html',
            controller: ModalWindow,
            transclude: {
                [transcludeNames.anchor]: '?modalAnchor',
                [transcludeNames.body]: '?modalBody',
                [transcludeNames.header]: '?modalHeader',
                [transcludeNames.controls]: '?modalControls'
            },
            bindings: {
                isLarge: '<',
                onInit: '<'
            }
        });

    function ModalWindow($transclude, $element, ModalHelper) {
        this.transcludeNames = transcludeNames;

        this.transcludeItemPresents = function(name) {
            return $transclude.isSlotFilled(name);
        };

        this.$onChanges = (changes) => {
            if (changes.onInit && changes.onInit.currentValue) {
                this.onInit($element.find('.modal'));
            }
        };

        this.showModal = () => {
            ModalHelper.showModalByElement($element.find('.modal'));
        };
    }
})();
